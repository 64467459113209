<template>
  <div class="container">
    <div class="logo">
      <img
        width="100"
        height="100"
        class="TextLogo"
        alt=""
        src="@/assets/images/footer_logo.png"
      />
      <!-- <h3 class="TextLogo"> Doctor <span style="color:primary;">4</span> Me</h3> -->
    </div>
    <div class="text-cnter">
      <p class="P-Text mt-8">{{ $t("travelArrangmentDiloag") }}</p>
      <p class="P-Text">{{ $t("travelArrangmentDiloagPlus") }}</p>
    </div>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";

export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
    };
  },
};
</script>
<style>
.container {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #004477 0%, #004477 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px;
  opacity: 1;
  text-align: center;
  width: 70%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: inline-block;

  width: 12rem;
  padding: 0.5rem;
}
.TextLogo {
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight: bold;
}
.P-Text {
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0px;

  opacity: 1;
}
</style>
